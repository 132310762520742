import React, { useEffect, useState } from 'react';
import { Container, Card, Form, Row, Col, Button, Modal, Image } from 'react-bootstrap';
import Select from 'react-select';  // Assuming you're using react-select for the country dropdown
import './App.css';
import CommonFun from './util/CommonFun';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import editIcon from '../src/assets/icons/edit.png';

function App() {
  const [formData, setFormData] = useState({
    country: '',
    lbs: '',
    oz: '',
    length: '',
    width: '',
    height: '',
  });

  const [errors, setErrors] = useState({});
  const [counties, setCounties] = useState([]);
  const [finalPrice, setFinalPrice] = useState(null);
  const [loading, setLoading] = useState(false);
  // const base_url = 'http://localhost:8000/';
  const base_url = 'https://dhlecbackend.maildepot.com/';
  const [fs, setFs] = useState(0)
  const [margin, setMargin] = useState(0)

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => {
    CommonFun.getData('userPreferences').then((data) => {
      if (data) {
        setFs(data.FS)
        setMargin(data.MARGIN)
      }
    });
    setShowModal(false)
  };


  useEffect(() => {
    // Fetch countries data from API
    fetch(base_url + 'api/get-country', {
      method: 'GET',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch countries');
        }
        return response.json();
      })
      .then((response) => {
        setCounties(response.data || []);
      })
      .catch((error) => {
        console.error('Error fetching countries:', error);
      });
    GetConstants()
  }, []);


  const GetConstants = () => {
    fetch(base_url + 'api/get-constants?keys=["FS","MARGIN"]', {
      method: 'GET',
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Failed to fetch constants');
        }
        return response.json();
      })
      .then((response) => {
        const apiConstants = response.data;

        // Retrieve previous constants and user preferences from local storage
        CommonFun.getData("PreviousConstant").then((data) => {
          // If no previous constants, store the values from API
          if (!data) {
            CommonFun.storeData("PreviousConstant", { FS: apiConstants.FS.value, MARGIN: apiConstants.MARGIN.value });
            CommonFun.storeData("userPreferences", { FS: apiConstants.FS.value, MARGIN: apiConstants.MARGIN.value });
            setMargin(apiConstants.MARGIN.value);
            setFs(apiConstants.FS.value);
          } else {
            // If previous constants exist, compare with user preferences
            CommonFun.getData('userPreferences').then((preference) => {
              let newConstant = { FS: preference.FS, MARGIN: preference.MARGIN };
              let updated = false;

              // Compare the backend constants with the stored preferences
              if (apiConstants.FS.value !== data.FS) {
                newConstant.FS = apiConstants.FS.value;
                updated = true;
              }
              if (apiConstants.MARGIN.value !== data.MARGIN) {
                newConstant.MARGIN = apiConstants.MARGIN.value;
                updated = true;
              }

              // If there was an update, store the updated constants and user preferences
              if (updated) {
                console.log("Updating constants and user preferences");
                CommonFun.storeData("PreviousConstant", newConstant);
                CommonFun.storeData("userPreferences", newConstant);
              } else {
                console.log("No changes detected, using existing values.");
              }

              // Update the state with the final values (either from backend or user preferences)
              setMargin(newConstant.MARGIN);
              setFs(newConstant.FS);
            });
          }
        });
      })
      .catch((error) => {
        console.error('Error fetching constants:', error);
      });
  };



  const handleChange = (e) => {
    const { id, value } = e.target;

    // Handle the lbs field (only allow integer values)
    if (id === 'lbs') {
      if (value === '' || /^[0-9]*$/.test(value)) { // Allow only positive integers
        setFormData((prevData) => {
          const updatedData = { ...prevData, [id]: value };
          handleSubmit(updatedData); // Pass updated data to handleSubmit
          return updatedData;
        });
      }
    } else {
      // Handle other fields (allow decimals and positive numbers)
      if (value === '' || /^[0-9]*\.?[0-9]*$/.test(value)) { // Allow positive decimals or integers
        setFormData((prevData) => {
          const updatedData = { ...prevData, [id]: value };
          handleSubmit(updatedData); // Pass updated data to handleSubmit
          return updatedData;
        });
      }
    }
  };

  const handleSubmit = (updatedFormData) => {
    const valid = validateForm(updatedFormData);
    if (valid) {
      calculatePrice(updatedFormData);
    } else {
      console.log("Form validation failed.");
    }
  };

  // Updated validateForm to accept form data as a parameter
  const validateForm = (data) => {
    const newErrors = {};

    if (!data.country || data.country === 'Select Country') {
      newErrors.country = 'Country is required';
    }
    if (!data.oz) {
      newErrors.oz = 'Oz is required';
    }
    if (!data.length) {
      newErrors.length = 'Length is required';
    }
    if (!data.width) {
      newErrors.width = 'Width is required';
    }
    if (!data.height) {
      newErrors.height = 'Height is required';
    }

    setErrors(newErrors);
    console.log(newErrors)
    return Object.keys(newErrors).length === 0;
  };

  // Updated calculatePrice to accept form data as a parameter
  const calculatePrice = (data) => {
    setLoading(true);
    const weight =
      (data.lbs ? parseFloat(data.lbs) : 0) +
      (data.oz ? parseFloat(data.oz) / 16 : 0);

    const dimensions =
      (data.length && data.width && data.height &&
        parseFloat(data.length) > 0 &&
        parseFloat(data.width) > 0 &&
        parseFloat(data.height) > 0)
        ? parseFloat(data.length) * parseFloat(data.width) * parseFloat(data.height) / 166
        : 0;

    const largerValue = Math.max(weight, dimensions);

    fetch(base_url + 'api/calculatePrice', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        weight: largerValue.toFixed(3),
        countryId: data.country,
        fs: fs,
        margin: margin,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setLoading(false);
        if (response.success) {
          setFinalPrice(response.finalPrice);
        } else {
          setFinalPrice(null);
          alert(response.message || "Some Error");
          console.error('Failed to calculate price');
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error calculating price:', error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClear = () => {
    setFormData({
      country: '',
      lbs: '',
      oz: '',
      length: '',
      width: '',
      height: '',
    });
    setFinalPrice(null);
  };
  return (
    <Container fluid className="d-flex justify-content-center align-items-center vh-100">
      <Card className="p-4 shadow-lg" style={{ width: '100%', maxWidth: '450px' }}>
        <h4 className='text-center'>DHL eCommerce (LAX)</h4>
        <div
          style={{
            position: 'absolute',
            padding: 5,
            right: 20,
            top: 20,
            cursor: 'pointer',
            borderRadius: 5,
          }}
          onClick={handleOpenModal}
        >
          <Image src={editIcon} alt="Edit Icon" style={{ width: '24px', height: '24px' }} />
        </div>

        <Form>
          {/* Select Country */}
          <Form.Group controlId="country" className="mb-4 mt-3">
            <Form.Label>Select Country</Form.Label>
            <Select
              options={counties.map((country) => ({
                value: country.id,
                label: country.country_name,
              }))}
              value={
                formData.country
                  ? counties
                    .map((country) => ({
                      value: country.id,
                      label: country.country_name,
                    }))
                    .find((option) => option.value === formData.country)
                  : null
              }
              onChange={(selectedOption) => {
                const updatedCountry = selectedOption ? selectedOption.value : '';
                setFormData((prevData) => {
                  const updatedData = { ...prevData, country: updatedCountry };

                  // Trigger handleSubmit after updating formData
                  handleSubmit(updatedData);
                  return updatedData;
                });
              }}
              placeholder="Select Country"
              isClearable
              className={errors.country ? 'is-invalid' : ''}
            />
            {errors.country && (
              <div className="invalid-feedback d-block">{errors.country}</div>
            )}
          </Form.Group>


          {/* Pound Fields */}
          <Row className="mb-4">
            <Col>
              <Form.Group controlId="lbs">
                <Form.Label>Lbs</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="0"
                  value={formData.lbs}
                  onChange={handleChange}
                  isInvalid={!!errors.lbs}
                />
                <Form.Control.Feedback type="invalid">{errors.lbs}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="oz">
                <Form.Label>Oz</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="0"
                  value={formData.oz}
                  onChange={handleChange}
                  isInvalid={!!errors.oz}
                />
                <Form.Control.Feedback type="invalid">{errors.oz}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          {/* Dimensions Fields */}
          <Row className="mb-4">
            <Col>
              <Form.Group controlId="length">
                <Form.Label>L</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="0"
                  value={formData.length}
                  onChange={handleChange}
                  isInvalid={!!errors.length}
                />
                <Form.Control.Feedback type="invalid">{errors.length}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="width">
                <Form.Label>W</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="0"
                  value={formData.width}
                  onChange={handleChange}
                  isInvalid={!!errors.width}
                />
                <Form.Control.Feedback type="invalid">{errors.width}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="height">
                <Form.Label>H</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="0"
                  value={formData.height}
                  onChange={handleChange}
                  isInvalid={!!errors.height}
                />
                <Form.Control.Feedback type="invalid">{errors.height}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          {/* Display final price */}
          <div className="mb-4">
            {finalPrice !== null && !loading && (
              <>
                <strong>Retail Price: </strong>${finalPrice.toFixed(2)}
              </>
            )}
          </div>

          {/* Loading Spinner */}
          {loading && <div>Loading...</div>}
          <Button variant="secondary" onClick={handleClear}>
            Clear
          </Button>
        </Form>
      </Card>
      {/* <div
        style={{ position: 'absolute', padding: 10, right: 40, top: 50, cursor: 'pointer', borderRadius: 5 }}
        className="shadow-lg"
        onClick={handleOpenModal}
      >
        <span style={{ fontWeight: '500' }}>
          Edit
        </span>
      </div> */}


      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Constants</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Form.Group controlId="fs">
            <Form.Label>FS</Form.Label>
            <Form.Control
              type="number"
              value={fs === null ? 0 : fs}
              onChange={(e) => setFs(parseFloat(e.target.value))}
            />
          </Form.Group> */}
          <Form.Group controlId="margin" className="mt-3">
            <Form.Label>Margin</Form.Label>
            <Form.Control
              type="number"
              value={margin === null ? 0 : margin}
              min={0}
              max={0.999} // Limit the maximum value to 0.999
              step="0.001" // Allow steps of three decimal places
              onChange={(e) => {
                const value = e.target.value;
                const numericValue = parseFloat(value);
                // Ensure the value is between 0 and 0.999 and has up to 3 decimal places
                if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 0.999) {
                  const formattedValue = Math.floor(numericValue * 1000) / 1000;
                  setMargin(formattedValue);
                } else if (value === "") {
                  setMargin(numericValue)
                }

              }}
            />
          </Form.Group>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              CommonFun.storeData('userPreferences', {
                FS: fs,
                MARGIN: margin
              });
              handleCloseModal();
              if (formData.country && formData.height && formData.length && formData.oz && formData.width) {
                handleSubmit(formData)
              }
            }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>

    </Container >
  );
}

export default App;

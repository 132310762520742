import AsyncStorage from "@react-native-async-storage/async-storage";

const storeData = async (key, value) => {
  try {
    await AsyncStorage.setItem(key, JSON.stringify(value));
    // console.log('Data successfully stored');
  } catch (error) {
    console.error('Error storing data', error);
  }
};

const getData = async (key) => {
  try {
    const value = await AsyncStorage.getItem(key);
    if (value !== null) {
      return JSON.parse(value);
    } else {
      // console.log('No data found for the key:', key);
    }
  } catch (error) {
    console.error('Error retrieving data', error);
  }
};

const removeData = async (key) => {
  try {
    await AsyncStorage.removeItem(key);
    // console.log('Data successfully removed');
  } catch (error) {
    console.error('Error removing data', error);
  }
};


export default { storeData, getData, removeData }


